// PrivacyPolicy.tsx
import React from 'react';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="p-3 text-left">
            <h2 className="text-2xl font-bold mb-4">Privacy Policy</h2>
            <p className="mb-3">ANDR3W AI ("ANDR3W", "we", "us", or "our"), located at 1173 South 250 West, Suite 507, Saint George, UT 84770, United States, is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our services.</p>

            <h3 className="text-xl font-bold mb-2">1. Information We Collect</h3>
            <p className="mb-3">We collect information that you provide directly to us, information we obtain automatically when you use our Services, and information from third-party sources. This may include:</p>
            <ul className="list-disc pl-5 mb-3">
                <li>Personal identifiers (e.g., name, email address, phone number)</li>
                <li>Account information</li>
                <li>Communication data (e.g., call recordings, transcripts)</li>
                <li>Usage data and analytics</li>
                <li>Device information</li>
            </ul>

            <h3 className="text-xl font-bold mb-2">2. How We Use Your Information</h3>
            <p className="mb-3">We use the information we collect to:</p>
            <ul className="list-disc pl-5 mb-3">
                <li>Provide, maintain, and improve our Services</li>
                <li>Process transactions and send related information</li>
                <li>Send you technical notices, updates, security alerts, and support messages</li>
                <li>Respond to your comments, questions, and customer service requests</li>
                <li>Develop new products and services</li>
                <li>Monitor and analyze trends, usage, and activities in connection with our Services</li>
                <li>Detect, investigate, and prevent fraudulent transactions and other illegal activities</li>
                <li>Comply with legal obligations</li>
            </ul>

            <h3 className="text-xl font-bold mb-2">3. Sharing of Information</h3>
            <p className="mb-3">We may share your information with:</p>
            <ul className="list-disc pl-5 mb-3">
                <li>Service providers and partners who perform services on our behalf</li>
                <li>In response to legal process or when required by law</li>
                <li>To protect our rights, privacy, safety, or property</li>
                <li>In connection with a merger, sale, or transfer of business assets</li>
            </ul>

            <h3 className="text-xl font-bold mb-2">4. Data Retention</h3>
            <p className="mb-3">We retain your information for as long as necessary to provide our Services and fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required by law.</p>

            <h3 className="text-xl font-bold mb-2">5. Security</h3>
            <p className="mb-3">We implement appropriate technical and organizational measures to protect your information against unauthorized or unlawful processing, accidental loss, destruction, or damage.</p>

            <h3 className="text-xl font-bold mb-2">6. Your Rights and Choices</h3>
            <p className="mb-3">Depending on your location, you may have certain rights regarding your personal information, including:</p>
            <ul className="list-disc pl-5 mb-3">
                <li>Access to your personal information</li>
                <li>Correction of inaccurate information</li>
                <li>Deletion of your personal information</li>
                <li>Restriction of processing</li>
                <li>Data portability</li>
                <li>Objection to processing</li>
            </ul>

            <h3 className="text-xl font-bold mb-2">7. Children's Privacy</h3>
            <p className="mb-3">Our Services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.</p>

            <h3 className="text-xl font-bold mb-2">8. Changes to This Privacy Policy</h3>
            <p className="mb-3">We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.</p>

            <h3 className="text-xl font-bold mb-2">9. International Data Transfers</h3>
            <p className="mb-3">Your information may be transferred to and processed in countries other than your own. We ensure appropriate safeguards are in place to protect your information in these cases.</p>

            <h3 className="text-xl font-bold mb-2">10. Contact Us</h3>
            <p className="mb-3">If you have any questions about this Privacy Policy, please contact us at:</p>
            <p>ANDR3W AI<br />
                1173 South 250 West, Suite 507<br />
                Saint George, UT 84770<br />
                United States<br />
                Email: privacy@andr3w.ai</p>

            <p className="mt-6 font-bold">Last Updated: August 20, 2024</p>
        </div>
    );
};

export default PrivacyPolicy;
import React from 'react';

const TermsOfService: React.FC = () => {
    return (
        <div className="p-3 text-left">
            <h2 className="text-2xl font-bold mb-4">Terms of Service</h2>
            <p className="mb-3">ANDR3W AI ("ANDR3W", "we", "us" or "our"), located at 1173 South 250 West, Suite 507, Saint George, UT 84770, United States, provides its services under the following terms and conditions. By accessing or using our Services, you ("Subscriber") agree to comply with and be bound by these Terms of Service.</p>

            <h3 className="text-xl font-bold mb-2">1. Compliance and Legal Obligations</h3>
            <p className="mb-3">Subscriber acknowledges that ANDR3W AI provides tools intended for responsible and lawful use. It is the sole responsibility of the Subscriber to ensure that all use of the Services complies with applicable laws and regulations, including but not limited to those governing automated or prerecorded calls and texts. Continued use of the Services requires strict adherence to these Terms.</p>

            <h4 className="text-lg font-bold mb-2">1.1 Prohibited Uses</h4>
            <p className="mb-3">Subscriber shall not use the Services to engage in any unlawful activities or unauthorized communications, including but not limited to unsolicited cold outbound calling, spamming, or phishing activities.</p>

            <h3 className="text-xl font-bold mb-2">2. Consent to Communications</h3>
            <p className="mb-3">By agreeing to these Terms, Subscriber consents to receiving communications from ANDR3W AI, including but not limited to automated, prerecorded, or live calls, SMS messages, and emails, at the contact information provided. You can opt-out at any time by following the instructions provided in each communication or by contacting us directly.</p>

            <h3 className="text-xl font-bold mb-2">3. Ownership and Use of Data</h3>
            <p className="mb-3">Subscriber retains ownership of their data submitted through the Services. ANDR3W AI is granted a license to use this data for providing and improving the Services. We commit to using Subscriber Data in aggregate and anonymized form only.</p>

            <h3 className="text-xl font-bold mb-2">4. Confidentiality</h3>
            <p className="mb-3">Both parties agree to maintain the confidentiality of any proprietary information shared during the course of using the Services. Unauthorized disclosure may result in irreparable harm.</p>

            <h3 className="text-xl font-bold mb-2">5. Indemnification</h3>
            <p className="mb-3">Subscriber agrees to indemnify and hold harmless ANDR3W AI from any claims, damages, or expenses arising from Subscriber's use of the Services or breach of these Terms.</p>

            <h3 className="text-xl font-bold mb-2">6. Limitation of Liability</h3>
            <p className="mb-3">To the fullest extent permitted by law, ANDR3W AI shall not be liable for any indirect, incidental, special, or consequential damages arising out of or related to the use of the Services.</p>

            <h3 className="text-xl font-bold mb-2">7. No Warranty</h3>
            <p className="mb-3">ANDR3W AI provides the Services on an "as is" and "as available" basis, without any warranties, either express or implied.</p>

            <h3 className="text-xl font-bold mb-2">8. Compliance with Communication Laws</h3>
            <p className="mb-3">Subscriber agrees to comply with all applicable laws governing automated or prerecorded calls, texts, and other communications, including but not limited to the TCPA and Telemarketing Sales Rule. ANDR3W AI may monitor service usage but assumes no responsibility for Subscriber's compliance.</p>

            <h3 className="text-xl font-bold mb-2">9. Dispute Resolution</h3>
            <p className="mb-3">Any dispute arising from these Terms shall be resolved by binding arbitration in Saint George, Utah.</p>

            <h3 className="text-xl font-bold mb-2">10. Governing Law</h3>
            <p className="mb-3">These Terms are governed by the laws of the State of Utah, without regard to its conflict of law principles.</p>

            <h3 className="text-xl font-bold mb-2">11. Intellectual Property</h3>
            <p className="mb-3">ANDR3W AI retains all rights to its technology and intellectual property. Subscriber agrees not to reverse engineer or attempt to derive the source code of the ANDR3W AI platform.</p>

            <h3 className="text-xl font-bold mb-2">12. Service Modifications</h3>
            <p className="mb-3">ANDR3W AI reserves the right to modify, suspend, or discontinue any part of the Services at any time with or without notice.</p>

            <h3 className="text-xl font-bold mb-2">13. Termination</h3>
            <p className="mb-3">ANDR3W AI may terminate or suspend access to the Services immediately, without prior notice, for any reason whatsoever.</p>

            <p className="mt-6 font-bold">By clicking "Call Me" or using our Services, you agree to receive autodialed calls and emails from ANDR3W AI and accept these Terms of Service. If you have any questions or concerns, please contact us at support@andr3w.ai.</p>
        </div>
    );
};

export default TermsOfService;